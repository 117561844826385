<template>
    <div id="app">

        <!-- Header -->
        <home-header ref="home-header"/>
        
        <!-- Main -->
        <router-view/>

        <!-- Footer -->
        <home-footer/>

    </div>
</template>

<script>
export default {


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            chat: false
        };

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Load Chat
         */
        chat_load() {

            // On Scroll 
            // => load chat on scroll
            window.addEventListener('scroll', () => 
                setTimeout(() => {
                    // init chat
                    this.chat_init();
                }, 1000),
                {once: true}
            );

            // After page was fully loaded + delay
            // => create an interval
            let stateCheck = setInterval(() => {
                // check if document readyState is complete
                if (document.readyState === 'complete') {
                    // if so clear interval
                    clearInterval(stateCheck);
                    // set a delay of 1 second
                    setTimeout(() => {
                        // init chat
                        this.chat_init();
                    }, 5000);
                }
            }, 100);

        },

        /**
         *  Initialize Chat
         */
        chat_init() {

            // check if chat is already initilaized
            if( this.chat !== false )
                return false;

            // set chat to loaded state
            this.chat = true;
            
            // set Intercom ID
            var intercom_id = "bs3h0lq8";

            // Intercom
            window.intercomSettings = {
                app_id: intercom_id
            };

            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function() {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function(args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + intercom_id;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                l();
            }

        }

	},


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {

        // load chat segments
        this.chat_load();

    },


    /** ----------------------------------------------------
     * Watch
     ----------------------------------------------------- */
    watch: {
        $route() {

            // update intercom on url change
            window.Intercom("update");

            // trigger analytics pageview
            window.posthog.capture('$pageview');
            
        }
    }

}
</script>