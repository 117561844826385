import Vue from 'vue'
import Router from 'vue-router'
import home from './views/home.vue'

// load Router
Vue.use(Router)

// Define Routes
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {},
            component: home
        },
        {
            path: '/features',
            name: 'features',
            meta: {},
            component: () => import('./views/features.vue')
        },
        {
            path: '/features/:id',
            name: 'features',
            meta: {},
            component: () => import('./views/features.vue')
        },
        {
            path: '/pricing',
            name: 'pricing',
            meta: {},
            component: () => import('./views/pricing.vue')
        },
        {
            path: '/about',
            name: 'about',
            meta: {},
            component: () => import('./views/about.vue')
        },
        {
            path: '/demo/',
            name: 'demo',
            meta: {},
            component: () => import('./views/demo.vue')
        },
        {
            path: '/careers/',
            name: 'careers',
            meta: {},
            component: () => import('./views/careers.vue')
        },
        {
            path: '/careers/:id',
            name: 'careers',
            meta: {},
            component: () => import('./views/landing-careers.vue')
        },
        {
            path: '/contact/',
            name: 'contact',
            meta: {},
            component: () => import('./views/contact.vue')
        },
        {
            path: '/booking/',
            name: 'booking',
            meta: {},
            component: () => import('./views/booking.vue')
        },
        {
            path: '/legal',
            name: 'legal',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/terms',
            name: 'terms',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/privacy',
            name: 'privacy',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/cookie',
            name: 'cookie',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/security',
            name: 'security',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/custom/:id',
            name: 'custom',
            meta: {},
            component: () => import('./views/custom.vue')
        },
        {
            path: '/customers/',
            name: 'customers',
            meta: {},
            component: () => import('./views/customers.vue')
        },
        {
            path: '/customers/:id',
            name: 'customers-item',
            meta: {},
            component: () => import('./views/landing-customers.vue')
        },
        {
            path: '/use-case/',
            name: 'usecase',
            meta: {},
            component: () => import('./views/landing-usecase.vue')
        },
        {
            path: '/use-case/:id',
            name: 'usecase',
            meta: {},
            component: () => import('./views/landing-usecase.vue')
        },
        {
            path: '/alternative/',
            name: 'alternative',
            meta: {},
            component: () => import('./views/landing-alternative.vue')
        },
        {
            path: '/alternative/:id',
            name: 'alternative',
            meta: {},
            component: () => import('./views/landing-alternative.vue')
        },
        {
            path: '/integration/',
            name: 'integration',
            meta: {},
            component: () => import('./views/landing-integration.vue')
        },
        {
            path: '/integration/:id',
            name: 'integration-item',
            meta: {},
            component: () => import('./views/landing-integration.vue')
        },
        {
            path: '/affiliates/',
            name: 'affiliates',
            meta: {},
            component: () => import('./views/affiliates.vue')
        },
        {
            path: '/ad/:id',
            name: 'ad',
            meta: {},
            component: () => import('./views/landing-ad.vue')
        },
        {
            path: '*',
            name: '404',
            meta: {},
            component: () => import('./views/error.vue')
        }
    ],
    scrollBehavior (to) {
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    }
})
