import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueHead from 'vue-head'

// import global components
import home_header from '@/components/header.vue'
import home_footer from '@/components/footer.vue'

// load global components
Vue.component('home-header', home_header);
Vue.component('home-footer', home_footer);

// load header
Vue.use(VueHead);

// load active product if we are not on production
if( process.env.NODE_ENV == 'production' ) {
    // production tips
    Vue.config.productionTip = false;
    // production devtools
    Vue.config.devtools = false;
    // hide errors
    Vue.config.errorHandler = function() {}
    // hide warnings
    Vue.config.warnHandler = function() {}
    // hide console
    console.log = function () {}; // eslint-disable-line no-console
    console.info = function () {}; // eslint-disable-line no-console
}

// load vue
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');

// load css
require('./assets/css/flexboxgrid.css');
require('./assets/css/home-style.css');
require('./assets/css/home-elements.css');
require('./assets/css/home-responsive.css');

// redirect to non wwww
if( (location.host).includes('www.') ) { location.href = 'https://sleekplan.com' + location.pathname }