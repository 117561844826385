<template>
    <footer class="footer" :class="'footer-' + this.$route.name">
        <div class="inner">

            <div class="row">
                <div class="col-sm col-xs-12 col-start">
                    <div class="comp">
                        <img src="@/assets/media/logo/logo.svg" width="40" height="40">
                    </div>
                    <div class="comp-slug">The all-in-one feedback tool for every business.</div>
                    <a :href="'mailto:' + support" class="comp-made" target="_blank" rel="nofollow">
                        <span>{{support}}</span>
                    </a>
                </div>
                <div class="col-sm col-xs-6">
                    <div class="head">Product</div>
                    <ul>
                        <li><a href="/features/" rel="nofollow">Features</a></li>
                        <li><a href="/use-case/">Use Cases</a></li>
                        <li><a href="/demo/" rel="nofollow">Demo</a></li>
                        <li><a href="/integration/">Integrations</a></li>
                    </ul>
                </div>
                <div class="col-sm col-xs-6">
                    <div class="head">Resources</div>
                    <ul>
                        <li><a href="https://help.sleekplan.com" rel="nofollow">Help Center</a></li>
                        <li><a href="/docs/">Developer Docs</a></li>
                        <li><a href="https://feedback.sleekplan.com/changelog" rel="nofollow" target="_blank" data-sleek-changelog data-badge-changelog>Product Updates</a></li>
                        <li><a href="https://feedback.sleekplan.com/roadmap" rel="nofollow" target="_blank">Roadmap</a></li>
                        <li><a href="/blog/">Blog</a></li>
                        <li><a href="https://status.sleekplan.com" rel="nofollow">Status</a></li>
                    </ul>
                </div>
                <div class="col-sm col-xs-6">
                    <div class="head">Sleekplan</div>
                    <ul>
                        <li><a href="/affiliates/" rel="nofollow">Affiliate Program</a></li>
                        <li><a href="/contact/" rel="nofollow">Contact us</a></li>
                        <li><a href="javascript:void(0)" id="csconsentlink">Cookie Settings</a></li>
                    </ul>
                </div>
                <div class="col-sm col-xs-6">
                    <div class="head">Compare</div>
                    <ul class="alternatives">
                        <li><a href="/alternative/canny-io/">Alternative to Canny.io</a></li>
                        <li><a href="/alternative/upvoty/">Alternative to Upvoty</a></li>
                        <li><a href="/alternative/uservoice/">Alternative to Uservoice</a></li>
                        <li><a href="/alternative/nolt-io/">Alternative to Nolt.io</a></li>
                        <li><a href="/alternative/getbeamer/">Alternative to Beamer</a></li>
                        <li><a href="/alternative/">More Alternatives</a></li>
                    </ul>
                </div>
            </div>

            <div class="bottom">
                <div class="start">
                    <span>© {{cyear}} Sleekplan</span>
                    <a href="/privacy/" rel="nofollow">Privacy policy</a>
                    <a href="/terms/" rel="nofollow">Terms of Service</a>
                    <a href="/legal/" rel="nofollow">Legal Notice</a>
                    <a href="/security/" rel="nofollow">Security</a>
                </div>
                <div class="end">
                    <a href="https://twitter.com/sleekplan" target="_blank" rel="nofollow">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Logo Twitter</title><path d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"/></svg>
                    </a>
                    <a href="https://www.linkedin.com/company/sleekplan" target="_blank" rel="nofollow">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Logo Linkedin</title><path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z"/></svg>
                    </a>
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
export default {


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            cyear: new Date().getFullYear(),
            support: 'support@sleekplan.com',
            chat: function(){ window.Intercom('show'); }
        };

    }

}
</script>